/*.order-wrap{
    display:flex;
    flex-direction:column;
    height:100%;
}*/

.order-wrap .up-side{
    padding-bottom:100px;
}

.order-wrap .down-side{
    position:fixed;
    text-align:center;
    bottom:15px;
    left:50%;
    width:200px;
    margin-left:-100px;
}

.ticket-item{
    display:flex;
    padding:0.4rem 0.4rem;
    margin:0;
}
.ticket-item:nth-child(odd){
    background-color:#8ec748;
    color:#fff;
}
.ticket-item .amount{
    text-align:right;
    width:100px;
}
.ticket-item .product{
    text-align:left;
    flex:1;
}