*{
    box-sizing:border-box;
    font-family:sans-serif;
    scroll-behavior:smooth;
}

body{
    margin:0;
    padding:0;
}

.card{
    background-color:#333;
}

.title{
    font-family:'Lobster';
}

.btn{
    margin:0;
    padding:1rem 1.2rem;
    font-size:1.4em;
    border:none;
    cursor:pointer;
    border-radius:50px;
}
.btn:disabled{
    background-color:#e3e3e3;
    color:#999;
}
.btn-small{
    font-size:0.8em;
    font-weight:bold;
}

.btn-primary{
    color:#fff;
    background-color:#8ec748;
}
.btn-secundary{
    color:#fff;
    background-color:#016648;
}
.btn-black{
    color:#fff;
    background-color:#333;
}

