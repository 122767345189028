.modal-wrap{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    display:none;
}

.modal-wrap.active{
    display:flex;
}

.modal-background{
    background-color:rgba(0,0,0,0.6);
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    display:none;
    z-index:1000;
}
.modal-background.active{
    display:block;
}

.modal{
    margin:auto;
    background-color:#fff;
    width:90%;
    max-width:600px;
    max-height:95%;
    z-index:1001;
    border-radius:0.6rem;
    display:none;
}

.modal.active{
    display:block;
}

.modal-header{
    margin:0;
    padding:1rem;
}
.modal-header h1,.modal-header h2,.modal-header h3,.modal-header h4,.modal-header h5,.modal-header h6{
    margin:0;
    padding:0;
    text-align:center;
}
.modal-body{
    margin:0;
    padding:1rem;
    overflow:auto;
    max-height:400px;
}
.modal-footer{
    margin:0;
    padding:1rem;
    text-align:center;
}

.modal-body .success{
    text-align:center;
}