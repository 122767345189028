.access-box{
    margin:0;
    padding:0;
    width:100%;
    height:100vh;
    background-color:#000;
    display:flex;
}

.access-box-centered{
    margin:20px auto 0 auto;
    max-width:400px;
}

.access-box-centered img{
    width:100%;
}

.access-card{
    margin:15px;
    padding:1rem;
    background-color:#fff;
    text-align:center;
    border-radius:0.6rem;
}

.access-card input{
    width:100%;
    padding:10px;
    outline:none;
    border: none;
    border-bottom:1px solid #000;
    margin:15px 0;
}

.access-card button{
    width:100%;
    margin-bottom:15px;
}