.product-item{
    background-color:#fff;
    border-radius:0.6rem;
    display:flex;
    overflow:hidden;
    min-width:320px;
    box-shadow:0px 0px 2px #999;
    margin:5px auto;
}
.product-item .image{
    padding-left:10px;
    align-items:center;
    display:flex;
    width:45px;
}
.product-item img{
    width:100%;
}

.product-item .name{
    flex:1;
    align-items:center;
    display:flex;
    padding:0 10px;
    flex-wrap:wrap;
}
.product-item .name p{
    margin:0;
}
.product-item .name span{
    margin:0;
    width:100%;
    font-size:0.7em;
}

.product-item .amount{
    padding:0 10px 0 0;
    width:80px;
    border-left:1px solid #333;
}
.product-item .amount label{
    width:100%;
    height:100%;
    display:flex;
    align-items:center;
}
.product-item input{
    padding:0 2px 0 10px;
    width:100%;
    border:none;
    outline:none;
    font-size:1.1em;
    text-align:right;
}
.product-item .amount p{
    padding:0;
}